import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import { MEDIA_QUERY, SPACING, COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import Image from '~/components/publicWebsiteV2/common/Image';
import { PageAnimationOuterWrapper } from '@zola/zola-ui/src/animations/PageAnimations/PageAnimation/PageAnimation';

const desktopTimerWidth = '40px';
const mobileTimerWidth = '24px';

const INDIVIDUAL_DURATION = 4;
const FADE_TRANSITION_DURATION = 2;
const INDIVIDUAL_WITH_FADE_DURATION = INDIVIDUAL_DURATION + FADE_TRANSITION_DURATION;

const buildCrossFade = (
  individualDuration: number,
  totalDuration: number,
  fadeTransitionDuration: number
) => keyframes`
  0% {
    opacity: 1;
  }
  ${(individualDuration / totalDuration) * 100}% {
    opacity: 1;
  }
  ${((individualDuration + fadeTransitionDuration) / totalDuration) * 100}% {
    opacity: 0;
  }
  ${100 - (fadeTransitionDuration / totalDuration) * 100}% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// This can be improved, will potentially refactor later. https://github.com/NewAmsterdamLabs/web-wedding/pull/4630/files#r1231557237
// for (let i = 0; i < imagesLength; i++) {
//   if (i = 0)
//   return
//   `&:first-of-type {
//   animation-delay: -0s;
//   }`
//   else
//   return
//   `&:nth-of-type(${i+1}) {
//   animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * (6-i)}s;`
//   }

const buildAnimationDelay = (imagesLength: number) => {
  switch (imagesLength) {
    case 6:
      return css`
        &:first-of-type {
          animation-delay: -0s;
        }
        &:nth-of-type(2) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 5}s;
        }
        &:nth-of-type(3) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 4}s;
        }
        &:nth-of-type(4) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 3}s;
        }
        &:nth-of-type(5) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 2}s;
        }
        &:nth-of-type(6) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 1}s;
        }
      `;
    case 5:
      return css`
        &:first-of-type {
          animation-delay: -0s;
        }
        &:nth-of-type(2) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 4}s;
        }
        &:nth-of-type(3) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 3}s;
        }
        &:nth-of-type(4) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 2}s;
        }
        &:nth-of-type(5) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 1}s;
        }
      `;
    case 4:
      return css`
        &:first-of-type {
          animation-delay: -0s;
        }
        &:nth-of-type(2) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 3}s;
        }
        &:nth-of-type(3) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 2}s;
        }
        &:nth-of-type(4) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 1}s;
        }
      `;
    case 3:
      return css`
        &:first-of-type {
          animation-delay: -0s;
        }
        &:nth-of-type(2) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 2}s;
        }
        &:nth-of-type(3) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 1}s;
        }
      `;
    case 2:
      return css`
        &:first-of-type {
          animation-delay: -0s;
        }
        &:nth-of-type(2) {
          animation-delay: -${INDIVIDUAL_WITH_FADE_DURATION * 1}s;
        }
      `;
    default:
      return css`
        &:first-of-type {
          animation-delay: -0s;
        }
      `;
  }
};

export const SlideshowContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  height: 100%;

  ${PageAnimationOuterWrapper} {
    height: 100%;
    > div {
      height: 100%;
      // for some reason wipe (which is an animated clip-path) will only work with this micro delay
      animation-delay: 0.1s;
    }
  }
`;

export const SlideshowSlider = styled.div<{ isSinglePage?: boolean }>`
  white-space: nowrap;
  transition: ease 1s;
  display: flex;
  flex-direction: row-reverse;
  ${({ isSinglePage }) => (isSinglePage ? 'height: 100%' : '')}
`;

export const SlideSized = styled(Image, {
  shouldForwardProp: (prop) => !['imagesLength', 'isSinglePage', 'inPreview'].includes(prop),
})<{
  imagesLength: number;
  isSinglePage?: boolean;
  inPreview?: 'DESKTOP' | 'MOBILE';
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  aspect-ratio: 12 / 7;
  object-fit: cover;
  ${({ imagesLength }) => {
    const TOTAL_DURATION = INDIVIDUAL_WITH_FADE_DURATION * imagesLength;
    const crossFade = buildCrossFade(INDIVIDUAL_DURATION, TOTAL_DURATION, FADE_TRANSITION_DURATION);
    return css`
      animation: ${crossFade} ${TOTAL_DURATION}s infinite;
      ${buildAnimationDelay(imagesLength)}
    `;
  }}
  ${MEDIA_QUERY.MOBILE} {
    aspect-ratio: 1 / 1;
  }
  ${({ inPreview }) => (inPreview === 'MOBILE' ? 'aspect-ratio: 1 / 1;' : '')}
  ${({ isSinglePage }) => (isSinglePage ? 'height: 100%; aspect-ratio: 1 / 1;' : '')}
`;

export const TimerBarContainer = styled.div<{ inPreview?: 'DESKTOP' | 'MOBILE' }>`
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  ${MEDIA_QUERY.MOBILE} {
    bottom: 52px;
  }
  ${({ inPreview }) => (inPreview === 'MOBILE' ? 'bottom: 52px;' : '')}
`;

const timerBar = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${desktopTimerWidth};
  }
`;
const timerBarMobile = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${mobileTimerWidth};
  }
`;

export const TimerContainer = styled.div<{ inPreview?: 'DESKTOP' | 'MOBILE' }>`
  position: relative;
  display: inline-block;
  border-radius: 3px;
  width: ${desktopTimerWidth};
  height: ${SPACING.XXS};
  margin: 0px ${SPACING.XXS};
  background-color: rgba(255, 255, 255, 0.3);
  ${MEDIA_QUERY.MOBILE} {
    width: ${mobileTimerWidth};
    margin: 0px ${SPACING.XXS};
  }
  ${({ inPreview }) =>
    inPreview === 'MOBILE'
      ? `
  width: ${mobileTimerWidth};
  margin: 0px ${SPACING.XXS};
  `
      : ''}
`;

const activeTimeStyles = (inPreview?: 'DESKTOP' | 'MOBILE') => css`
  animation: ${inPreview === 'MOBILE' ? timerBarMobile : timerBar} 5s linear;
  ${MEDIA_QUERY.MOBILE} {
    animation: ${timerBarMobile} ${INDIVIDUAL_WITH_FADE_DURATION}s linear;
  }
`;

export const Timer = styled.div<{ active?: boolean; inPreview?: 'DESKTOP' | 'MOBILE' }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 3px;
  width: ${desktopTimerWidth};
  ${MEDIA_QUERY.MOBILE} {
    width: ${mobileTimerWidth};
  }
  ${({ inPreview }) => (inPreview === 'MOBILE' ? `width: ${mobileTimerWidth};` : '')}

  // uncomment and fix this in a fast follow
  /* ${({ active, inPreview }) => active && activeTimeStyles(inPreview)}; */

  ${({ active }) =>
    active &&
    `
    background-color:  ${COLORS3.WHITE_100};`}
`;
