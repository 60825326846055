import React, { useContext } from 'react';

// context
import { DeviceContext } from '~/contexts/DeviceContext';
// types
import type { SinglePageHeroVariantProps } from '~/components/publicWebsiteV2/common/SinglePageHeroContainer/types';

// util
import { getWebsitePlaceholderAsset } from '~/components/manage/EditWebsite/common/HeroCustomizer/utils';

// components
import WebsiteSlideshow from '~/components/publicWebsiteV2/common/WebsiteSlideshow';

// styles
import { BaseHero } from '~/components/publicWebsiteV2/common/SinglePageHeroContainer/SinglePageHeroContainer.styles';

const SinglePageHeroSlideshow: React.FC<SinglePageHeroVariantProps> = ({
  children,
  images,
  inPreview,
  previewContainerHeight,
}) => {
  const { device } = useContext(DeviceContext);

  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());

  const slideshowImages = [
    getWebsitePlaceholderAsset('SLIDESHOW_SINGLE_PAGE', isDesktop, 1),
    getWebsitePlaceholderAsset('SLIDESHOW_SINGLE_PAGE', isDesktop, 2),
  ];

  if (images && images.length > 0) {
    images.forEach((image) => {
      if (image.position && image.image_url) {
        slideshowImages[image.position - 1] = image.image_url;
      }
    });
  }

  return (
    <BaseHero overrideHeight={previewContainerHeight} data-testid="SinglePageHeroSlideshow">
      <WebsiteSlideshow slideshowImages={slideshowImages} isSinglePage inPreview={inPreview} />
      {children}
    </BaseHero>
  );
};

export default SinglePageHeroSlideshow;
